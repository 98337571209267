import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { GenericGridComponent } from '../../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../../common/components/loading/loading.service';
import { AlertifyService } from '../../../../common/services/alertify.service';
import { CurrencyVM, CustomerBalanceVM, CustomerMovementVM, CustomerStatementVM, DeleteCustomerMovement, LoginResponse, PaymentMethodVM, SaleVM } from '../../../../models/view-models';
import { CustomerMovementService } from '../../../../services/customer-movement.service';
import { LocalData } from '../../../../models/LocalData';
import { UnpaidSalesFromCustomerSelectorComponent } from '../../../../common/components/unpaid-sales-from-customer-selector/unpaid-sales-from-customer-selector.component';
import { environment } from '../../../../../environments/environment';
import { ShiftService } from '../../../../services/shift.service';
import { MultiplePaymentMethodComponent } from '../../../../common/components/multiple-payment-method/multiple-payment-method.component';
import { DeleteConfirmDialogComponent } from '../../../../common/components/delete-confirm-dialog/delete-confirm-dialog.component';
import { AuthenticationService } from '../../../../interceptors/authentication.service';
import { CustomerMovementType } from '../../../../models/MovementType';
import { ListDetailsComponent } from '../../../../common/components/list-details/list-details.component';
import { PaymentMethodService } from '../../../../services/payment-method.service';
import { CurrencyService } from '../../../../services/currency.service';
import { CustomValidators } from '../../../../common/validators/CustomValidators';
import { PaymentMethodButtonSelectorComponent } from '../../../../common/components/payment-method-button-selector/payment-method-button-selector.component';
import { TicketsService } from '../../../../services/tickets.service';

@Component({
  selector: 'app-customers-info',
  templateUrl: './customers-info.component.html',
  styleUrls: ['./customers-info.component.scss']
})
export class CustomersInfoComponent implements OnInit {

  balances: CustomerBalanceVM[] = [];
  selectedbalance: CustomerBalanceVM;
  customerMovements: CustomerMovementVM[] = [];
  selectedMovement: CustomerMovementVM;
  entityForm: FormGroup;
  editionForm: FormGroup;
  showDetails: boolean = false;
  showForm: boolean = false;
  isEdition: boolean = false;

  @ViewChild("grid", { read: GenericGridComponent, static: true })
  public grid: GenericGridComponent;

  @ViewChild("detailsGrid", { read: GenericGridComponent, static: true })
  public detailsGrid: GenericGridComponent;

  @ViewChild("details", { read: ListDetailsComponent })
  public details: ListDetailsComponent;

  showEdition: boolean = false;

  customerBalance: number = 0;

  @ViewChild("paymentMethodSelector", { read: PaymentMethodButtonSelectorComponent })
  public paymentMethodSelector: PaymentMethodButtonSelectorComponent;

  hideReferenceInput: boolean = true;
  @ViewChild("amount") amount: ElementRef;
  @ViewChild("reference") reference: ElementRef;
  @ViewChild("amountToEdit") amountToEdit: ElementRef;

  @ViewChild("unpaidSalesSelector", { read: UnpaidSalesFromCustomerSelectorComponent })
  public unpaidSalesSelector: UnpaidSalesFromCustomerSelectorComponent;

  payAmount: number = 0;
  isTicketSelected: boolean = false;
  hideCancelButton: boolean = false;

  customerStatement: CustomerStatementVM;

  customerName: String = "";
  totalCustomerBalance: number = 0;

  @ViewChild("multiplePay", { read: MultiplePaymentMethodComponent })
  public multiplePay: MultiplePaymentMethodComponent;
  hideMultiplePay: boolean = true;

  @ViewChild("confirmDialog", { read: DeleteConfirmDialogComponent })
  public confirmDialog: DeleteConfirmDialogComponent;

  userId: string = "";
  userRol: string = "";

  allowPositiveBalance: boolean = false;

  isCurrencyPaymentMethod: boolean = false;
  currencyCode: string = "";
  currencyTotal: number = 0;
  currencyValue: number = 0;
  totalAmount: number = 0;

  showPartialPayments: boolean = false;
  _customerId: string = "ff2b0aa9-2af7-4065-8771-6ca861143c86";

  username: string;

  constructor(private customerMovementService: CustomerMovementService,
    private currencyService: CurrencyService,
    private shiftService: ShiftService,
    private ticketService:TicketsService,
    private fb: FormBuilder,
    private alertify: AlertifyService,
    private decimalPipe: DecimalPipe,
    private authService: AuthenticationService,
    private paymentMethodService: PaymentMethodService,
    private currencyPipe: CurrencyPipe,
    private loading: LoadingService) {

    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userRol = usr.Rol;
    this.username = usr.User;

  }

  ngOnInit(): void {
    this.initForm();
    this.onFormChanges();
    this.loadData();

  }

  initForm() {
    this.entityForm = this.fb.group({
      Amount: [this.setFormatNumber(0), [Validators.required]],
      BankReference: ['', [CustomValidators.removeSpecialCharacters]]
    });
  }

  initEditionForm() {
    this.editionForm = this.fb.group({
      Amount: [this.setFormatNumber(this.selectedMovement.Amount), [Validators.required]],
    });
  }

  onFormChanges() {
    this.entityForm.valueChanges
      .subscribe(value => {
        try {
          this.payAmount = parseFloat(value.Amount);
          if (this.isCurrencyPaymentMethod) {
            this.currencyTotal = value.Amount;
            this.totalAmount = value.Amount * this.currencyValue;
          }
        } catch (e) {
          this.payAmount = 0;
        }
      });
  }

  loadData() {
    this.loading.show();
    this.customerMovementService.getCustomersBalance()
      .subscribe((response: CustomerBalanceVM[]) => {
        if (response) {
          this.balances = response;
        }
        this.setGridOptions();
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setGridOptions() {
    this.grid.setData(this.balances);
    this.grid.options.toolbarTitle = "Saldos";
    this.grid.options.hideActionButtons = true;
    this.grid.options.rowClick = true;
    this.grid.options.paging = false;
    this.grid.columns = [
      { field: "FullName", title: "Cliente" },
      { field: "TotalPurchased", title: "Total de Compras", currencyType: true },
      { field: "TotalPaid", title: "Total de Pagos", currencyType: true },
      //{ field: "LastPay", title: "Último Pago", dateType: true },
      { field: "Balance", title: "Saldo", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  select(balance: CustomerBalanceVM) {
    this.customerName = balance.FullName;
    this.allowPositiveBalance = balance.PositiveBalanceAllowed;
    this.totalCustomerBalance = balance.Balance;
    this.selectedbalance = balance;
    this.loadCustomerMovements();
  }

  loadCustomerMovements() {
    this.loading.show();
    this.customerMovementService.getFromCustomer(this.selectedbalance.CustomerId)
      .subscribe((response: CustomerMovementVM[]) => {
        if (response) {
          response.forEach(item => {
            item.MovementTypeName = this.customerMovementService.setMovementTypeName(item.MovementType);
          });

          this.customerMovements = response;
          this.showDetails = true;
          this.setDetailsGridOptions();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  setDetailsGridOptions() {
    this.detailsGrid.setData(this.customerMovements);
    this.detailsGrid.options.toolbarTitle = "Movimientos de Cliente";
    this.detailsGrid.options.showEditButton = this.userIsAdmin();
    this.detailsGrid.options.showDeleteButton = this.userIsAdmin();
    this.detailsGrid.options.showPrintButton = true;
    this.detailsGrid.options.paging = false;
    this.detailsGrid.columns = [
      { field: "TicketId", title: "Ticket" },
      { field: "CreateDate", title: "Fecha", dateType: true },
      { field: "CreateDate", title: "Hora", timeType: true },
      { field: "MovementTypeName", title: "Tipo de Movimiento" },
      { field: "Amount", title: "Monto", currencyType: true },
      { field: "PaymentMethodName", title: "Método de Pago" },
    ];
    this.detailsGrid.setGridOptions();
  }

  userIsAdmin(): boolean {
    if (this.userRol === LocalData.ADMIN_ROL)
      return true;
    return false;
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  setFormVisibility = () => this.showForm = !this.showForm;

  disableActionButtons(): boolean {
    if (this.selectedbalance) {
      return this.selectedbalance.Balance <= 0;
    }
    return true;
  }

  createPay() {
    this.unpaidSalesSelector.getSales(this.selectedbalance.CustomerId);
    this.setFormVisibility();
    this.paymentMethodSelector.reset();
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    try {
      switch (method.Code) {
        case LocalData.PAYMENT_METHOD_CASH: this.hideBankReferenceAndMultiplePayment();
          this.focusOnAmountInput();
          break;
        case LocalData.PAYMENT_METHOD_VOUCHER: this.hideBankReferenceAndMultiplePayment();
          this.focusOnAmountInput();
          break;
        case LocalData.PAYMENT_METHOD_CARD: this.hideReferenceInput = false;
          this.focusOnAmountInput();
          break;
        case LocalData.PAYMENT_METHOD_EBANK: this.hideReferenceInput = false;
          this.focusOnAmountInput();
          break;
        case LocalData.PAYMENT_METHOD_MULTIPLE: this.hideMultiplePay = false;
          this.entityForm.controls["Amount"].setValue(0);
          this.multiplePay.focusOnCash();
          break;
        default: this.hideBankReferenceAndMultiplePayment();
          this.focusOnAmountInput();
          break;
      }
      if (method.IsCurrency) {
        this.paymentMethodIsCurrency(method.Code);
      }
    } catch (e) {
      alert(e);
    }
  }

  hideBankReferenceAndMultiplePayment() {
    this.hideReferenceInput = true;
    this.hideMultiplePay = true;
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  focusOnAmountInput() {
    setTimeout(() => {
      this.amount.nativeElement.focus();
      this.amount.nativeElement.select();
    }, 0);
  }

  focusOnAmountToEditInput() {
    setTimeout(() => {
      this.amountToEdit.nativeElement.focus();
      this.amountToEdit.nativeElement.select();
    }, 0);
  }

  isValidForm(): boolean {
    return this.entityForm.valid
      && this.payAmount > 0
      && this.unpaidSalesSelector.getSelected() != null;
  }

  isEditionFormValid(): boolean {
    return this.editionForm.valid;
  }

  onTicketSelected(isSelected: boolean) {
    this.isTicketSelected = isSelected;
    this.hideCancelButton = isSelected;
  }

  save() {
    if (this.isValidForm()) {
      let newMovement: CustomerMovementVM = Object.assign({
        CustomerId: this.selectedbalance.CustomerId,
        MovementType: 'D',
        PaymentMethodCode: this.paymentMethodSelector.getItemCode(),
        PaymentMethodName: this.paymentMethodSelector.getItemName(),
        SaleId: this.unpaidSalesSelector.getSelectedId(),
        ShiftId: this.shiftService.getActiveShiftId(),
        Username: this.username
      }, this.entityForm.value);

      if (newMovement.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
        newMovement.MultiplePayments = this.multiplePay.getCustomerPayments();
      }

      if (this.isCurrencyPaymentMethod) {
        newMovement.Amount = this.totalAmount;
      }

      if (newMovement.Amount > this.unpaidSalesSelector.saleBalance.TotalPending
        && !this.allowPositiveBalance) {
        this.alertify.warning("El monto del abono no puede ser mayor al monto pendiente de pago");
      } else {
        this.loading.show();
        this.customerMovementService.createDeposit(newMovement)
          .subscribe((response: CustomerMovementVM) => {
            if (response) {
              this.openTicket(response.TicketUrl);
              this.reset();
              this.showDetails = false;
              this.loadData();
            }
          }, (err) => {
            this.loading.hide();
          });
      }
    }

  }

  reset() {
    this.isTicketSelected = false;
    this.hideCancelButton = false;
    this.unpaidSalesSelector.reset();
    this.entityForm.reset();
    this.hideReferenceInput = true;
    this.showForm = false;
    this.isCurrencyPaymentMethod = false;
    this.totalAmount = 0;
    this.currencyValue = 0;
    this.currencyTotal = 0;
    this.currencyCode = '';
  }

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  openStatement(filename: string): void {
    const url = `${environment.customerStatementsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  createStatement() {
    this.loading.show();
    this.customerMovementService.getCustomerStatement(this.selectedbalance.CustomerId)
      .subscribe((response: CustomerStatementVM) => {
        if (response) {
          this.customerStatement = response;
          this.openStatement(this.customerStatement.TicketUrl);
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  onMultiplePaymentChange(value: number) {
    this.entityForm.controls["Amount"].setValue(value);
  }

  delete(value: CustomerMovementVM) {
    if (value.MovementType === CustomerMovementType.PAY
      || value.MovementType === CustomerMovementType.DEPOSIT) {
      if (this.userIsAdmin()) {
        this.selectedMovement = value;
        let amnt: string = this.currencyPipe.transform(value.Amount);
        this.confirmDialog.openDialog(this.userId, value.UUID,
          `¿Desea eliminar el ${value.MovementTypeName} de ${value.CustomerName} por un monto de ${amnt}?`);
      }
    } else {
      this.alertify.warning(`No es posible eliminar movimientos de tipo ${value.MovementTypeName} desde aquí.`);
    }
  }

  confirmDelete(dcm: DeleteCustomerMovement) {
    this.loading.show();
    this.customerMovementService.deletePay(dcm)
      .subscribe((response: boolean) => {
        if (response) {
          this.confirmDialog.closeDialog();
          this.selectedbalance.Balance += this.selectedMovement.Amount;
          this.loadCustomerMovements();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
      });
  }

  edit(value: CustomerMovementVM) {
    if (value.MovementType === CustomerMovementType.PAY
      || value.MovementType === CustomerMovementType.DEPOSIT) {
      if (this.userIsAdmin()) {
        if (value.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
          this.alertify.warning("No es posible editar movimientos con método de pago múltiple");
        } else {
          this.selectedMovement = value;
          this.showEdition = true;
          this.initEditionForm();
          this.details.items = [
            { Title: "Tipo de Movimiento", Value: this.selectedMovement.MovementTypeName },
            { Title: "Fecha de registro", Value: this.selectedMovement.CreateDate, IsDate: true },
            { Title: "Método de Pago", Value: this.paymentMethodService.getPaymentMethodName(this.selectedMovement.PaymentMethodCode) },
          ];
          if (this.selectedMovement.PaymentMethodCode === LocalData.PAYMENT_METHOD_EBANK
            || this.selectedMovement.PaymentMethodCode === LocalData.PAYMENT_METHOD_CARD) {
            this.details.items.push(
              { Title: "Referencia Bancaria", Value: this.selectedMovement.BankReference },
            );
          }
          this.focusOnAmountToEditInput();
        }
      }
    } else {
      this.alertify.warning(`No es posible eliminar movimientos de tipo ${value.MovementTypeName} desde aquí.`);
    }
  }

  update() {
    if (this.isEditionFormValid()) {
      this.loading.show();
      const um: CustomerMovementVM = Object.assign({
        UUID: this.selectedMovement.UUID,
        CustomerId: this.selectedMovement.CustomerId,
        SaleId: this.selectedMovement.SaleId,
        SalesIds: this.selectedMovement.SalesIds,
        MovementType: this.selectedMovement.MovementType,
        CreateDate: this.selectedMovement.CreateDate,
        PaymentMethodCode: this.selectedMovement.PaymentMethodCode,
        PaymentMethodName: this.selectedMovement.PaymentMethodName,
        BankReference: this.selectedMovement.BankReference,
        ShiftId: this.selectedMovement.ShiftId,
      }, this.editionForm.value);
      this.customerMovementService.update(um)
        .subscribe((response: CustomerMovementVM) => {
          if (response) {
            this.detailsGrid.updateRowByUUID(response);
            this.selectedbalance.Balance = (this.selectedbalance.Balance + this.selectedMovement.Amount) - response.Amount;
          }
          this.cancelEdition();
          this.loading.hide();
        }, (err) => {
          this.loading.hide();
        })

    }
  }

  cancelEdition() {
    this.details.items = [];
    this.showEdition = false;
  }

  paymentMethodIsCurrency(code: string) {
    let currency: CurrencyVM = this.currencyService.getLocalCurrency(code);
    this.currencyValue = currency.Exchange;
    //this.currencyTotal = this.saleBalance.TotalPending / currency.Exchange;
    this.currencyCode = currency.Code;
    this.isCurrencyPaymentMethod = true;

  }

  onPartialPaymentsCancel() {
    this.showPartialPayments = false;
  }

  onPartialPaymentsClose() {
    this.showPartialPayments = false;
    this.loadData();
  }

  reprint(mov:CustomerMovementVM) {
    this.loading.show();
    this.ticketService.getTicketFilename(mov.TicketId)
      .subscribe((filename: string) => {
        if (filename)
          this.openTicket(filename);
        this.loading.hide();
      }); 
  }

}
