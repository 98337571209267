import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertifyService } from '../common/services/alertify.service';
import { map } from 'rxjs/operators';
import { ErrorService } from '../interceptors/error.service';
import { ImportProductsResult, OperationResult } from '../models/view-models';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  apiUrl: string = `${environment.apiUrl}/Upload`;

  constructor(private http: HttpClient,
    private alertify: AlertifyService,
    private error: ErrorService) { }

  uploadProducts(file: any) {
    const uri = `${this.apiUrl}/UploadProducts`;
    return this.http.post(uri, file)
      .pipe(map((response: OperationResult<ImportProductsResult>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

  uploadLogo(file: any) {
    const uri = `${this.apiUrl}/UploadLogo`;
    return this.http.post(uri, file)
      .pipe(map((response: OperationResult<boolean>) => {
        if (response.Success) {
          this.alertify.success("Se agregaron los productos del archivo csv");
          return true;
        } else {
          this.error.logError(response);
          return false;
        }
      }));
  }

  importProductsFromçPos(file: any) {
    const uri = `${this.apiUrl}/ImportProductsFromçPos`;
    return this.http.post(uri, file)
      .pipe(map((response: OperationResult<ImportProductsResult>) => {
        if (response.Success) {
          return response.Data;
        } else {
          this.error.logError(response);
          return null;
        }
      }));
  }

}
