import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MovementType, TipoDeMovimiento } from '../../../../../models/MovementType';
import { MovementVM } from '../../../../../models/view-models';
import { ShiftService } from '../../../../../services/shift.service';

@Component({
  selector: 'modal-cash-out',
  templateUrl: './cash-out.component.html',
  styleUrls: ['./cash-out.component.scss']
})
export class CashOutComponent {

  showDialog: boolean = false;
  dialogForm: FormGroup;
  movement: MovementVM;
  @ViewChild("amount") amount: ElementRef;
  @ViewChild('conceptInput') conceptInput: ElementRef;
  @ViewChild('saveButton') saveButton: ElementRef;

  @Output() cashOutEmiter: EventEmitter<MovementVM> = new EventEmitter<MovementVM>();
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  userId: string = "";

  constructor(private shiftService: ShiftService,
    private fb: FormBuilder) { }

  initForm() {
    this.dialogForm = this.fb.group({
      Amount: ['', [Validators.required]],
      Concept: ['']
    });
  }
  focusOnAmountInput() {
    setTimeout(() => {
      this.amount.nativeElement.focus();
    }, 0);
  }

  isValidForm(): boolean { return this.dialogForm.valid; }

  public openDialog(user_id: string,) {
    this.userId = user_id;
    this.showDialog = true;
    this.initForm();

    this.focusOnAmountInput();
  }

  createMovement() {
    if (this.isValidForm()) {
      this.movement = Object.assign({
        UserId: this.userId,
        MovementType: MovementType.CASH_OUT,
        MovementTypeName: TipoDeMovimiento.O,
        CreateDate: new Date(),
        ShiftId: this.shiftService.getActiveShiftId()
      }, this.dialogForm.value);
      this.cashOutEmiter.emit(this.movement);
    }
  }

  closeDialog() {
    this.showDialog = false;
    this.modalClose.emit();
  }

  conceptFocus(event: KeyboardEvent) {
    event.preventDefault();
    setTimeout(() => {
      this.conceptInput.nativeElement.focus();
    }, 0);
  }

  saveFocus(event: KeyboardEvent) {
    event.preventDefault();
    setTimeout(() => {
      this.saveButton.nativeElement.focus();
    }, 0);
  }



}
