import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared.module';
import { CommonComponentsModule } from '../common/common-components.module';

import { HomeRoutingModule } from './home-routing.module';

import { HomeComponent } from './home.component';
import { MenuComponent } from './common/menu/menu.component';
import { SaleComponent } from './components/sale/sale.component';
import { ProductsComponent } from './components/products/products.component';
import { CreditsComponent } from './components/credits/credits.component';
import { CustomersComponent } from './components/customers/customers.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { BillsComponent } from './components/bills/bills.component';
import { ReportsComponent } from './components/reports/reports.component';
import { CashClosingComponent } from './components/cash-closing/cash-closing.component';
import { CommonProductComponent } from './components/sale/modals/common-product/common-product.component';
import { MultipleInsertionComponent } from './components/sale/modals/multiple-insertion/multiple-insertion.component';
import { CashInComponent } from './components/sale/modals/cash-in/cash-in.component';
import { CashOutComponent } from './components/sale/modals/cash-out/cash-out.component';
import { PriceConsultationComponent } from './components/sale/modals/price-consultation/price-consultation.component';
import { ChangeTicketComponent } from './components/sale/modals/change-ticket/change-ticket.component';
import { ReprintLastTicketComponent } from './components/sale/modals/reprint-last-ticket/reprint-last-ticket.component';
import { SalesOfTheDayAndReturnsComponent } from './components/sale/modals/sales-of-the-day-and-returns/sales-of-the-day-and-returns.component';
import { SaleItemComponent } from './components/reports/sale-item/sale-item.component';
import { TotalByItemComponent } from './components/reports/total-by-item/total-by-item.component';
import { SaledProductsComponent } from './components/reports/saled-products/saled-products.component';
import { CardexFormComponent } from './components/inventory/cardex-form/cardex-form.component';
import { CardexComponent } from './components/inventory/cardex/cardex.component';
import { InventoryProductsComponent } from './components/inventory/inventory-products/inventory-products.component';
import { UniqueProductComponent } from './components/products/unique-product/unique-product.component';
import { CARDEXReportComponent } from './components/reports/cardex-report/cardex-report.component';
import { PosComponent } from './pos/pos.component';
import { TicketsComponent } from './components/tickets/tickets.component';
import { TicketsDialogComponent } from './components/sale/modals/tickets-dialog/tickets-dialog.component';
import { SingleShiftCashCloseComponent } from './components/cash-closing/single-shift-cash-close/single-shift-cash-close.component';
import { MultipleShiftCashCloseComponent } from './components/cash-closing/multiple-shift-cash-close/multiple-shift-cash-close.component';
import { SalesOnCreditComponent } from './components/credits/sales-on-credit/sales-on-credit.component';
import { CustomersInfoComponent } from './components/credits/customers-info/customers-info.component';
import { SalesOfTheDayComponent } from './components/sales-of-the-day/sales-of-the-day.component';
import { ModifyTicketComponent } from './components/sales-of-the-day/modify-ticket/modify-ticket.component';
import { ReturnProductsComponent } from './components/sales-of-the-day/return-products/return-products.component';
import { CancelTicketComponent } from './components/sales-of-the-day/cancel-ticket/cancel-ticket.component';
import { ExitComponent } from './components/sale/modals/exit/exit.component';
import { SaleErrorDialogComponent } from './components/sale/modals/sale-error-dialog/sale-error-dialog.component';
import { DateRangeReportComponent } from './components/reports/date-range-report/date-range-report.component';
import { StartingCashDialogComponent } from './components/starting-cash-dialog/starting-cash-dialog.component';
import { ServicePayModule } from './components/service-pay/service-pay.module';
import { PartialPaymentsComponent } from './components/credits/partial-payments/partial-payments.component';


@NgModule({
  declarations: [
    HomeComponent,
    MenuComponent,
    SaleComponent,
    ProductsComponent,
    CreditsComponent,
    CustomersComponent,
    InventoryComponent,
    PurchasesComponent,
    BillsComponent,
    ReportsComponent,
    CashClosingComponent,
    CommonProductComponent,
    MultipleInsertionComponent,
    CashInComponent,
    CashOutComponent,
    PriceConsultationComponent,
    ChangeTicketComponent,
    ReprintLastTicketComponent,
    SalesOfTheDayAndReturnsComponent,
    StartingCashDialogComponent,
    SalesOnCreditComponent,
    CustomersInfoComponent,
    SalesOfTheDayComponent,
    ModifyTicketComponent,
    ReturnProductsComponent,
    CancelTicketComponent,
    ExitComponent,
    TotalByItemComponent,
    CardexFormComponent,
    InventoryProductsComponent,
    CARDEXReportComponent,
    TicketsComponent,
    SingleShiftCashCloseComponent,
    MultipleShiftCashCloseComponent,
    SaleErrorDialogComponent,
    DateRangeReportComponent,
    SaleItemComponent,
    SaledProductsComponent,
    CardexComponent,
    UniqueProductComponent,
    PosComponent,
    PartialPaymentsComponent,

  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SharedModule,
    CommonComponentsModule,
    ServicePayModule
  ]
})
export class HomeModule { }
